@font-face {
  font-family: "icomoon";
  src: url("../assets/fonts/icomoon.eot?h9y8gr");
  src: url("../assets/fonts/icomoon.eot?h9y8gr#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/icomoon.ttf?h9y8gr") format("truetype"),
    url("../assets/fonts/icomoon.woff?h9y8gr") format("woff"),
    url("../assets/fonts/icomoon.svg?h9y8gr#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-down:before {
  content: "\e903";
}
.icon-chevron-up:before {
  content: "\e902";
}
.icon-close:before {
  content: "\e901";
}
.icon-bars:before {
  content: "\e900";
}
